import React, { Component } from 'react';
import { gsap, Power3 } from 'gsap';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

// import ReactGA from 'react-ga';
import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';
import besties_title_en from '../../images/besties-en.png';
import besties_title_fr from '../../images/besties-fr.png';
import besties_coke from '../../images/besties-coke.svg';
import besties_coke_fr from '../../images/besties-coke-fr.svg';
import besties_oreo from '../../images/besties-oreo.svg';
import besties_heart from '../../images/besties-bigheart.svg';

import packshot_angle from '../../images/packshot-angle.png';
import cookie_stack from '../../images/bottle-stack.png';
import cookie_angle from '../../images/cookie-angle.png';
import cookie_open from '../../images/cookie-open.png';
import packshot from '../../images/packsot.png';
import besties_title_stacked_en from '../../images/besties-stack-en.png';
import besties_title_stacked_fr from '../../images/besties-stack-fr.png';

import img1 from '../../images/image-1.jpg';
import img2 from '../../images/image-2.jpg';
import img3 from '../../images/BBS_OREO_CA_ENG_DAVID_SOC_P003808_STRIPED_-_-_-_2024SEP30_VID06-4X5_FBIGINFEED-PXPORECA00440.gif';
import img3fr from '../../images/BBS_OREO_CA_FRA_DAVID_SOC_P003808_STRIPED_-_-_-_2024SEP30_VID06-4X5_FBIGINFEED-PXPORECA00495_1.gif';
import img4 from '../../images/image-4.jpg';
import img5 from '../../images/image-5.jpg';
import img6 from '../../images/image-6.jpg';
import img7 from '../../images/image-7.jpg';
import img7fr from '../../images/image-7-fr.jpg';

class Homepage extends Component {

    state = {
        isPlaying: true,
    }

    handleVideoClick = () => {
        // console.log('click',this.state.isPlaying);
        this.setState({isPlaying:!this.state.isPlaying});
        if (this.state.isPlaying){
            this.videl.pause();
        } else {
            this.videl.play();
        }
        
    }

    getBuyNowLink = ()=>{
        let link = 'https://www.amazon.ca/dp/B0DDLHKMPF';
        // if (this.props.lang==='fr-CA') link += '&language=fr_CA';
        return link;
    }
    getBestieLink = ()=>{
        let link = (this.props.lang==='fr-CA') ? 'https://www.bestiemode.com/ca/fr' : 'https://www.bestiemode.com/ca/en';
        return link;
    }

    componentDidMount(){

    }

    componentWillUnmount(){
        
    }

    render(){

        return (
            <Controller>
                <div id="main" className="main-wrapper">
                <div className="container home">
                        <Scene>
                            <div className="row bg-home align-items-center">
                                <div className="col-12 col-md-5 home-text-container text-center"> {/*before it was col-md-6*/}
                                    <div className="besties-hero">
                                        <Tween from={{ alpha:1, scale: 0 }} to={{ alpha:0, scale:1, delay: 1 }} duration={1.5}><img src={besties_heart} id="heart1" className="" alt="BESTIES FOR A LIMITED TIME" /></Tween>
                                        <Tween from={{ scale: 0 }} to={{ scale:.44, delay: 1.5 }} duration={1}><img src={besties_heart} id="heart2" className="" alt="BESTIES FOR A LIMITED TIME" /></Tween>
                                        <Tween from={{ opacity:0, x:100, y:-100, scale: 1.2, delay: .5 }} duration={1}><img src={besties_oreo} id="heartright" className="" alt="BESTIES FOR A LIMITED TIME" /></Tween>
                                        <Tween from={{ opacity:0, x:-100, y:-100, scale: 1.2, delay: .5 }} duration={1}><img src={this.props.lang==='fr-CA'?besties_coke_fr:besties_coke} id="heartleft" className="" alt="BESTIES FOR A LIMITED TIME" /></Tween>
                                    </div>  
                                    <Tween from={{ opacity: 0, y:50, delay: 2 }} stagger={.2} wrapper={<div/>}>
                                        <img src={this.props.lang==='fr-CA'?besties_title_fr:besties_title_en} className="img-fluid" alt="BESTIES FOR A LIMITED TIME" id="besties-head" />
                                        <p className="comingsoon-body mt-3 mx-4" id="besties-coming" >{translate('home.subtitle')}</p>
                                    </Tween>
                                </div>
                                <div className="col-12 col-md-7 text-center px-md-5">
                                    <Tween from={{ opacity:0, x:100, delay: 2.5 }}>
                                        <img src={packshot_angle} alt="Canadian Pack Shot." className="img-fluid mb-3" />
                                    </Tween>
                                    <Tween from={{ opacity:0, y:50, delay: 2.7 }}>
                                        <span>{translate('home.intro.cta')}</span>
                                    </Tween>
                                </div>
                                <div className="bubble-group" id="bubs-1" />
                                <div className="bubble-group delay1" id="bubs-2" />
                            </div>
                        </Scene>

                        <Scene duration={'30%'}>
                            {(progress)=>(
                                <div className="row align-items-center" id="cookie-stack">
                                    <div className="col-12 col-md-3 offset-md-1">
                                        <Tween from={{ opacity:0, x:-100 }} stagger={.2} totalProgress={progress} paused>
                                        <h2 className="mb-4">{translate('home.section1.title')}</h2>
                                        <p>{translate('home.section1.body',{br:<br/>,sup:e=><sup>{e}</sup>})}</p>
                                        </Tween>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <Tween from={{ opacity:0, x:100 }} totalProgress={progress} paused>
                                        <img src={cookie_stack} alt='Cookie coke bottle. SERVING SIZE 2 COOKIES' className='img-fluid'/>
                                        </Tween>
                                    </div>
                                    <div className="bubble-group delay2" id="bubs-3" />
                                    <div className="bubble-group" id="bubs-4" />
                                </div>
                            )}
                        </Scene>

                        <Scene duration={'30%'}>
                            {(progress)=>(
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-5 order-md-2">
                                        <Tween from={{ opacity:0, x:100 }} stagger={.2} totalProgress={progress} paused>
                                        <h3 className="mb-4">{translate('home.section2.title')}</h3>
                                        <p>{translate('home.section2.body',{br:<br/>,sup:e=><sup>{e}</sup>})}</p>
                                        </Tween>
                                    </div>
                                    <div className="col-12 col-md-4 offset-md-1 order-md-1">
                                        <Tween from={{ opacity:0, x:-100 }} totalProgress={progress} paused>
                                        <img src={cookie_angle} alt='Cookie Side 1 Red Cola-Cola Embossed Wafer' className='img-fluid'/>
                                        </Tween>
                                    </div>
                                    <div className="bubble-group delay1" id="bubs-5" />
                                </div>
                            )}
                        </Scene>
                        
                        <Scene duration={'30%'}>
                            {(progress)=>(
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-5 offset-md-1">
                                        <Tween from={{ opacity:0, x:-100 }} totalProgress={progress} paused>
                                        <p>{translate('home.section3.body')}</p>
                                        </Tween>
                                    </div>
                                    <div className="col-12 col-md-5">
                                        <Tween from={{ opacity:0, x:100 }} totalProgress={progress} paused>
                                        <img src={cookie_open} alt='Cookie Side 2 OREO Embossed Water' className='img-fluid'/>
                                        </Tween>
                                    </div>
                                    <div className="bubble-group delay2" id="bubs-6" />
                                </div>
                            )}
                        </Scene>
                    </div>
                    

                    <div className="container-fluid home-bottom">

                        <Scene duration={'30%'}>
                            {(progress)=>(
                            <div className="row"><div className="container"><div className="row">
                                <div className="col-12 col-md-6 order-md-2">
                                    <Tween from={{ opacity:0, x:100 }} totalProgress={progress} paused>
                                    <img src={this.props.lang==='fr-CA'?besties_title_stacked_fr:besties_title_stacked_en} className="img-fluid mb-4" alt="Besties Forever. A Limited Time." />
                                    </Tween>
                                </div>
                                <div className="col-12 col-md-6 order-md-1"> 
                                    <Tween from={{ opacity:0, x:-100 }} totalProgress={progress} paused>
                                    <img src={packshot} className="img-fluid mb-4" alt="Canadian Pack Shot" />
                                    </Tween>
                                </div>
                                <div className="col-12 col-md-6 offset-md-6 text-center order-md-3">
                                    <Tween from={{ opacity:0, x:100, delay: .4 }} stagger={.2} totalProgress={progress} paused>
                                    <p>{translate('home.section4.body')}</p>
                                    <a className="button mt-4" href={this.getBuyNowLink()} target="_blank" rel="noopener noreferrer">
                                        {translate('home.section4.cta')}
                                    </a>
                                    </Tween>
                                </div>
                                
                            </div></div></div>
                            )}
                        </Scene>

                        <Scene classToggle="active">
                        <div className="row" id="video">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 text-left text-md-center tease-vid">
                                        <h4 className="mb-4">{translate('home.section5.title')}</h4>
                                        <video controls={true} controlsList="nodownload noplaybackrate noremoteplayback" width="100%" playsInline={true} autoPlay={false} 
                                            onClick={this.play} onPlaying={e=>e.target.classList.toggle('playing',true)} onPause={e=>e.target.classList.toggle('playing',false)}
                                            src={this.props.lang==='fr-CA' ? '/video/BBS_OREO_CA_FRA_DAVID_VID_P003808_SENSORIAL_-_-_-_2024SEP30_VID30-16X9_YTTRUEVIEW-PXPORECA00462_1.mp4' : '/video/BBS_OREO_CA_ENG_DAVID_VID_P003808_SENSORIAL_-_-_-_2024SEP30_VID30-16X9_YTTRUEVIEW-PXPORECA00407_1.mp4'}>
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Scene>

                        <Scene classToggle="active">
                            <div className="row" id="wall">
                                <div className="col-12 mb-4">
                                    <img src={img1} alt="Enjoyed Together" />
                                    <img src={img2} alt="Coke Bottle surrounded by Oreos" />
                                    <img src={this.props.lang==='fr-CA'?img3fr:img3} alt="Friends in a heart" />
                                    <img src={img4} alt="Abstract Oreo pattern" />
                                    <img src={img5} alt="Abstract Coke bottle pattern" />
                                    <img src={img6} alt="Oreos in a heart" />
                                    <img src={this.props.lang==='fr-CA'?img7fr:img7} alt="Besties Forever. A limited time" />
                                </div>
                                <div className="col-12 text-center  mt-4">
                                <a className="button" href={this.getBestieLink()} target="_blank" rel="noopener noreferrer">
                                        {translate('home.section6.cta')}
                                    </a>
                                </div>
                            </div>
                        </Scene>
                    </div>
                </div>
                
            </Controller>
        );
    }
}

export default Homepage;