import { LOCALES } from '../locales.js';

export default {
    [LOCALES.ENGLISH]: {

        //global
        'site.title' : 'OREO BESTIE',
        'close': 'Close',

        //header

        //nav
        'menu.item.home' : 'HOME',
        // 'menu.item.winner' : 'PAST WINNERS',
        // 'menu.item.1': 'QUIZ',
        // 'menu.item.2': 'OREO <i>STAR WARS</i> COOKIES',
        // 'menu.item.3': 'VIDEO',
        // 'menu.item.4': 'THE ART OF GREG HILDEBRANDT',

        'contact-email': 'customercare@oreobestie.ca',

        //footer
        'footer.link1' : 'Rules',
        'footer.link2' : 'Privacy Policy',
        'footer.link3' : 'Quebec Privacy Officer',
        'footer.link4' : 'Terms of Use',
        'footer.link5' : 'Accessibility Policy',
        'footer.sponsor': 'Sponsor: Mondelez Canada Inc., 277 Gladstone Avenue, Toronto, ON, M6J 3L9 Mondelez International group.{br}{br}®/™Coca-Cola Ltd., used under license.',

        'legal' : '',
        'legal.link' : 'oreobestie.ca',
        'legal.copy' : '®',

        //homepage
        'home.title': 'BESTIES FOREVER. A LIMITED TIME.',
        'home.subtitle': 'TASTE IT WHILE IT LASTS.',
        'home.intro.cta': 'Scroll down',
        'home.section1.title': 'BESTIES ARE ALWAYS BETTER TOGETHER.',
        'home.section1.body': 'Did we just become Best Friends? It’s as if the two of us were destined to be together.{br}{br}To celebrate our friendship, OREO and the iconic Coca-Cola™ have joined forces to create a very special limited-edition cookie.',
        'home.section2.title': 'SOMETHING’S FIZZING',
        'home.section2.body': 'We think Coca-Cola™ OREO sandwich cookies are the ultimate treat to share and gift to your bestie.{br}{br}Each cookie has a red and black colour-blocked design featuring one signature OREO chocolate Coca-Cola™ wafer cookie on the one side and a red-coloured Golden OREO wafer embossed with one of three unique designs on the other.',
        'home.section3.body': 'Plus, each comes stuffed with an OREO crème and popping candy, so each bite transports you to the experience of drinking a beverage.',
        'home.section4.body': 'While friendship is forever, this limited time engagement is not, so taste it while it lasts.',
        'home.section4.cta': 'shop now',
        'home.section5.title': 'WATCH US BECOME BESTIES',
        'home.section6.cta': 'Back to Bestie mode',


        
        'enterCta' : 'ENTER NOW',
        'enterNow' : 'ENTER NOW',

         //coming soon and home page
        'comingsoon.body' : 'COMING SOON – SEPTEMBER\u00a09\u00a02024',


    }
}