import {LOCALES} from '../locales.js';

export default {
    [LOCALES.FRENCH]: {

        //global
        'site.title' : 'OREO BESTIE',
        'close': 'Proche',

        //header

        //nav
        'menu.item.home' : 'ACCUEIL',
        // 'menu.item.winner' : 'ANCIENS GAGNANTS',
        // 'menu.item.1': 'JEU-QUESTIONNAIRE',
        // 'menu.item.2': 'BISCUITS OREO <i>STAR WARS</i>',
        // 'menu.item.3': 'VIDÉO',
        // 'menu.item.4': 'L’OEUVRE DE GREG HILDEBRANDT',
        
        'contact-email': 'customercare@oreobestie.ca',

        //footer
        'footer.link1' : 'Règlement',
        'footer.link2' : 'Politique de confidentialité',
        'footer.link3' : 'Responsable de la protection de la vie privée, Québec',
        'footer.link4' : 'Conditions d’utilisation',
        'footer.link5' : 'Politique d’accessibilité',
        'footer.sponsor': 'Commanditaire : Mondelez Canada Inc., 277 Gladstone Avenue, Toronto, ON, M6J 3L9 groupe ©Mondelez International. MD/MC Coca-Cola ltée, utilisées sous licence.',

        //homepage
        'home.title': 'MEILLEURS AMIS POUR LA VIE. UN TEMPS LIMITÉ.',
        'home.subtitle': 'NE TARDEZ PAS À Y GOÛTER.',
        'home.intro.cta': 'Défilez vers le bas',
        'home.section1.title': 'LES MEILLEURS AMIS SONT EN HARMONIE.',
        'home.section1.body': 'On est des meilleurs amis maintenant? C’est comme si on était fait l’un pour l’autre.{br}{br}Afin de célébrer notre amitié, OREO et l’emblématique Coca-Cola<sup>MC</sup> ont fait équipe pour créer un biscuit série limitée, unique en son genre.',
        'home.section2.title': 'IL Y A DU PÉTILLEMENT DANS L’AIR',
        'home.section2.body': 'On pense que Coca-Cola<sup>MC</sup> et les biscuits-sandwiches OREO, c’est le meilleur cadeau à partager avec ton ou ta meilleure amie.{br}{br}Chaque biscuit-sandwiche est doté d’un côté rouge et d’un côté noir. Il y a une gaufrette au chocolat signature de OREO et une autre gaufrette de couleur rouge présentant l’un de trois motifs uniques du logo Coca-Cola<sup>MC</sup>.',
        'home.section3.body': 'De plus, chaque biscuit contient une crème OREO avec bonbons pétillants; chaque bouchée vous donnera l’impression de boire la boisson gazeuse.',
        'home.section4.body': 'Même si l’amitié, c’est pour toujours, cette série limitée ne l’est pas, alors ne tardez pas à y goûter.',
        'home.section4.cta': 'MAGASINEZ MAINTENANT',
        'home.section5.title': 'VOYEZ NOTRE AMITIÉ SE DÉVELOPPER',
        'home.section6.cta': 'Retournez à En mode meilleurs amis',

        'enterCta' : 'PARTICIPEZ MAINTENANT',
        'enterNow' : 'PARTICIPEZ MAINTENANT',

        'legal' : '',
        'legal.link' : 'oreobestie.ca',
        'legal.copy' : 'MD',

        //homapge
        'comingsoon.body' : 'À VENIR – 9\u00a0SEPTEMBRE\u00a02024',

    }
}