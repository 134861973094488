import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
// import ReactGA from 'react-ga';

// import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';
import besties_title_en from '../../images/besties-en.png';
import besties_title_fr from '../../images/besties-fr.png';
import besties_coke from '../../images/besties-coke.svg';
import besties_coke_fr from '../../images/besties-coke-fr.svg';
import besties_oreo from '../../images/besties-oreo.svg';
import besties_heart from '../../images/besties-bigheart.svg';
// import besties_smheart from '../../images/besties-smheart.svg';
// import packshot from '../../images/besties-heart.png';

class ContestClosed extends Component {


    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);


        //GSAP animation for the heading elements
        // gsap.from('#heartleft, #heartright, #heart2', 1.5, {
        //     opacity: 0,
        //     scale: 1.5,
        //     ease: Power3.easeOut,
        //     stagger: .3
        // });

        
        gsap.from('#heartright',{opacity:0,x:100,y:-100,scale:1.2,delay:.5,duration:1});
        gsap.from('#heartleft',{opacity:0,x:-100,y:-100,scale:1.2,delay:.5,duration:1});
        gsap.from('#heart1',{scale:0,delay:1,duration:1.5});
        gsap.fromTo('#heart2',{scale:0},{scale:.44,delay:1.5,duration:1});

        gsap.from('#besties-head,#besties-coming',{opacity:0,delay:2,stagger:.2});

    }

    componentWillUnmount(){
        
    }

    render(){
        return (
            <Fragment>
                <div id="main" className="main-wrapper">
                    <div className="container home coming-soon">
                        <div className="row bg-home">
                            <div className="col col-md-6 home-text-container text-center"> {/*before it was col-md-6*/}

                                <div className="besties-hero">
                                    <img src={besties_heart} id="heart1" className="" alt="BESTIES FOR A LIMITED TIME" />
                                    <img src={besties_heart} id="heart2" className="" alt="BESTIES FOR A LIMITED TIME" />
                                    <img src={besties_oreo} id="heartright" className="" alt="BESTIES FOR A LIMITED TIME" />
                                    <img src={this.props.lang==='fr-CA'?besties_coke_fr:besties_coke} id="heartleft" className="" alt="BESTIES FOR A LIMITED TIME" />
                                </div>
                            
                                <div className="">     
                                    <img src={this.props.lang==='fr-CA'?besties_title_fr:besties_title_en} className="img-fluid" alt="BESTIES FOR A LIMITED TIME" id="besties-head" />
                                    <p className="comingsoon-body mt-3 mx-4" id="besties-coming" >{translate('comingsoon.body',{b:c=><b>{c}</b>,em:c=><em>{c}</em>})}</p>
                                </div>

                                
                          
                            </div>
                        </div>
                    </div>
                    <div className="bubble-group" id="bubs-0" />
                </div>
                
            </Fragment>
        );
    }
}

export default ContestClosed;